webApp = angular.module('webApp');

var url_api = "/api/";

webApp.factory('baseHttp', ['$http', function ($http) {

    return {

        postEnviarMensajeContacto: function postEnviarMensajeContacto(data) {
            return $http.post(url_api + 'contacto/mensaje', data, {
                headers: {'Content-Type': undefined },
                transformRequest: angular.identity
            });
        }

    }

}]);