webApp.directive('menuStyleScrollbar', function () {
    return {
        scope: {
            offset: '@offset'

        },
        link: function (scope, element, attrs) {
            var width = $(window).width();
            if(width >=768){
                $(window).scroll(function () {
                    if (window.pageYOffset >= scope.offset) {
                        $(element).css("visibility", "visible");
                        $('.seccion-principal-container-contenido-header').css("visibility", "hidden");

                    } else {
                        $('.seccion-principal-container-contenido-header').css("visibility", "visible");
                        $(element).css("visibility", "hidden");
                    }
                });
            }


        }
    };
});