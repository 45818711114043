webApp.factory('objectFit', ['$document', function ($document) {

    return {
        run : function(){

            $(function () {
                var $someImages = $('.img-object-fit-element');
                objectFitImages($someImages, {watchMQ: true})
            });

        }
    };

}]);
