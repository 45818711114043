webApp.controller('BaseController', ['$scope', '$window', '$sce', '$interval', '$timeout', 'baseHttp', 'scrollFocus', 'SweetAlert',
    'objectFit','$cookieStore',
    function ($scope, $window, $sce, $interval, $timeout, baseHttp, scrollFocus, SweetAlert, objectFit, $cookieStore) {

        var baseVm = this;
        baseVm.menuShowActivo = false;
        baseVm.menuIdiomasShowActivo = false;
        baseVm.panelContactoActivo = false;



        /* $window.onload = function () {
            objectFit.run();
        };*/


        baseVm.menuShow = function(origen){

            if(origen == "principal"){

                baseVm.menuShowActivo = !baseVm.menuShowActivo;

            }else{
                baseVm.menuIdiomasShowActivo = !baseVm.menuIdiomasShowActivo;
            }



        };

        baseVm.menuSelect = function(id){

            baseVm.menuShowActivo = false;
            baseVm.menuIdiomasShowActivo = false;

            scrollFocus.animate(id);


        };

        baseVm.animateElementIn = function ($el, s) {
            $el.removeClass('not-visible');
            $el.addClass('animated animation ' + $el[0].attributes.classElementIn.value);
        };

        baseVm.animateElementOut = function ($el) {
            $el.addClass('not-visible');
            $el.removeClass('animated animation ' + $el[0].attributes.classElementIn.value);
        };

        baseVm.panelShowcontacto = function(){

            baseVm.panelContactoActivo = !baseVm.panelContactoActivo;
            baseVm.panelContactoNombre = "";
            baseVm.panelContactoTelefono = "";
            baseVm.panelContactoEmail = "";
            baseVm.panelContactoMensaje = "";
            baseVm.panelContactoNombreError = false;
            baseVm.panelContactoTelefonoError = false;
            baseVm.panelContactoEmailError = false;
            baseVm.panelContactoMensajeError = false;

            if(baseVm.panelContactoActivo){
                $timeout(function(){
                    scrollFocus.animate('form-contacto');
                }, 10);

            }

        };

        baseVm.enviarMensaje = function(){

            var idioma = $cookieStore.get('idioma') ? $cookieStore.get('idioma') : 'es';

            baseVm.panelContactoNombreError = false;
            baseVm.panelContactoTelefonoError = false;
            baseVm.panelContactoEmailError = false;
            baseVm.panelContactoMensajeError = false;

            if(baseVm.panelContactoNombre){

                if(baseVm.panelContactoTelefono){

                    if(baseVm.panelContactoEmail){

                        if(baseVm.panelContactoMensaje){

                            var data = new FormData();

                            data.append('nombre', baseVm.panelContactoNombre);
                            data.append('telefono',baseVm.panelContactoTelefono);
                            data.append('email', baseVm.panelContactoEmail);
                            data.append('mensaje', baseVm.panelContactoMensaje);

                            baseHttp.postEnviarMensajeContacto(data).then(function (resultado) {

                                baseVm.panelContactoActivo = false;

                                if(idioma == 'es'){
                                    SweetAlert.swal("Mensaje enviado", " Tu mensaje ha sido enviado exitosamente te  " +
                                        "estaremos contactando lo antes posible.", "success");
                                }else{
                                    SweetAlert.swal("Message sent", " Your message has been sent successfully, we will be contacting you as soon as possible.", "success");
                                }


                            }).catch(function (resultado) {
                                if(idioma == 'es'){
                                    SweetAlert.swal("Error", 'Lo sentimos su mensaje no puede ser enviado, int\u00E9ntelo nuevamente m\u00E1s tarde.', "error");
                                }else{
                                    SweetAlert.swal("Error", 'Sorry your message can not be sent. try again later', "error");
                                }
                            });

                        }else{
                            baseVm.panelContactoMensajeError = true;
                            if(idioma == 'es'){
                                SweetAlert.swal("Error", 'Lo sentimos su mensaje no puede ser enviado, su mensaje es requerido.', "error");
                            }else{
                                SweetAlert.swal("Error", 'Sorry your message can not be sent, your message is required.', "error");
                            }

                        }
                    }else{
                        baseVm.panelContactoEmailError = true;
                        if(idioma == 'es'){
                            SweetAlert.swal("Error", 'Lo sentimos su mensaje no puede ser enviado, su email es requerido.', "error");
                        }else{
                            SweetAlert.swal("Error", 'Sorry your message can not be sent, your email is required.', "error");
                        }

                    }
                }else{
                    baseVm.panelContactoTelefonoError = true;
                    if(idioma == 'es'){
                        SweetAlert.swal("Error", 'Lo sentimos su mensaje no puede ser enviado, su tel\u00E9fono es requerido.', "error");
                    }else{
                        SweetAlert.swal("Error", 'Sorry your message can not be sent, your phone is required.', "error");
                    }

                }
            }else{
                baseVm.panelContactoNombreError = true;
                if(idioma == 'es'){
                    SweetAlert.swal("Error", 'Lo sentimos su mensaje no puede ser enviado, Su nombre es requerido.', "error");
                }else{
                    SweetAlert.swal("Error", 'Sorry your message can not be sent, Your name is required.', "error");
                }
            }


        };

        baseVm.setLang = function (idioma) {

            $cookieStore.put('idioma', idioma ? idioma : 'es');

            console.log($cookieStore.get('idioma'));

        };



    }]);