scrollFocus = angular.module('webApp');

scrollFocus.factory('scrollFocus', ['$document', '$location', '$anchorScroll', function ($document, $location, $anchorScroll) {

    return {
        animate: function (id) {

            $('html,body').animate({scrollTop: $("#" + id).offset().top}, 3000);

        }
    };

}]);
